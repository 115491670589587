import { type FC, useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import { createSearchParams, useNavigate } from "react-router-dom";

import { NumberFormat } from "@/app/components";
import { HookForm, SubmitButton, useControlledField, useHookForm } from "@/app/form";
import { useLocationPath } from "@/app/hooks";
import { getBonusMainPercent } from "@/entities/bonuses/helpers";
import { BonusTooltip } from "@/entities/bonuses/tooltip";
import { getVerificationRoute } from "@/features/onboarding/helpers";
import { PaymentFooter } from "@/features/payment/ui/footer";
import { formatInputNumberValue, getInputNumberValue } from "@/features/terminal/helpers/formatting";
import { useDisclosure } from "@/hooks/disclosure.hook";
import type { BonusUserPlatform, MaximumLimitDescription, PaymentMethod, TradingAccount } from "@/services/openapi";
import { Chip, NumberInput, Switch, Text } from "@/shared/ui";
import { useProfileData } from "@/state/server/profile/profile.hooks";

import { VerificationContainer } from "../../components/verification/verification.container";

enum Fields {
  AMOUNT = "amount",
}

type FormValues = {
  [Fields.AMOUNT]: string;
};

type Props = {
  withdrawLimit: MaximumLimitDescription;
  isKycCompleted: boolean;
  currentLimitBeforeKyc: number;
  currentAmount: string;
  amountSuggestions: number[];
  currentPaymentMethod: PaymentMethod;
  currentAccount: TradingAccount;
  bonus: BonusUserPlatform | null;
  isBonusAccepted: boolean;
  bonusAmount: number | void;
  next: () => void;
  setCurrentAmount: (value: string) => void;
  setIsBonusAccepted: (value: boolean) => void;
};

const AmountStep: FC<Props> = ({
  withdrawLimit,
  currentAmount,
  amountSuggestions,
  currentLimitBeforeKyc,
  currentPaymentMethod,
  currentAccount,
  next,
  setCurrentAmount,
  isKycCompleted,
  setIsBonusAccepted,
  bonus,
  isBonusAccepted,
  bonusAmount,
}) => {
  const { t } = useTranslation();
  const path = useLocationPath();
  const navigate = useNavigate();
  const profileData = useProfileData();
  const isSurveyMandatory = profileData.featuresInfo!.mandatorySurvey!;
  const isSurveyCompleted = profileData.options!.isSurveyCompleted!;
  const verificationRoute = getVerificationRoute(isSurveyCompleted);

  const [isVerificationDialogVisible, { close: closeVerificationDialog, open: openVerificationDialog }] =
    useDisclosure();

  const decimalScale = currentAccount.digits!;
  const currency = currentAccount.currency!;
  const limit = useMemo(
    () => currentPaymentMethod.details!.limits?.find(item => item.currency === currency),
    [currency, currentPaymentMethod],
  );

  const limitMax = limit?.platformMax ? limit.platformMax : void 0;
  const limitMin = limit?.platformMin ? limit.platformMin : void 0;

  const form = useHookForm<FormValues>({
    defaultValues: {
      [Fields.AMOUNT]: formatInputNumberValue(currentAmount, decimalScale),
    },
  });

  const {
    control,
    watch,
    setValue,
    formState: { errors },
  } = form;

  const [field, { invalid, pending }] = useControlledField<FormValues>({
    name: Fields.AMOUNT,
    control,
    rules: { required: true, min: limitMin, max: limitMax },
  });

  const { amount } = watch();

  const errorType = errors[Fields.AMOUNT]?.type;

  const isMaxAmountError = errorType === "max";
  const isMinAmountError = errorType === "min" || errorType === "required";

  const numberAmount = getInputNumberValue(amount);

  const isMoreThanLimit = numberAmount && !isKycCompleted ? numberAmount > currentLimitBeforeKyc : false;

  const handleSubmit = ({ amount }: FormValues) => {
    setCurrentAmount(amount);

    if (isMoreThanLimit) {
      navigate({ pathname: verificationRoute, search: createSearchParams({ from: path }).toString() });
      return;
    }

    if (!isKycCompleted) {
      openVerificationDialog();
      return;
    }

    next();
  };

  return (
    <>
      <HookForm form={form} onSubmit={handleSubmit}>
        <div className="flex flex-col gap-4">
          {amountSuggestions.length > 0 && (
            <Chip.Group value={amount} onValueChange={value => setValue(Fields.AMOUNT, value)}>
              {amountSuggestions.map(value => (
                <Chip key={value} value={formatInputNumberValue(value, decimalScale)}>
                  <NumberFormat
                    value={value}
                    decimalScale={decimalScale}
                    fixedDecimalScale={false}
                    currency={currency}
                  />
                </Chip>
              ))}
            </Chip.Group>
          )}

          <NumberInput
            placeholder={`0 ${currency}`}
            currency={currency}
            decimalScale={decimalScale}
            pending={pending}
            invalid={invalid}
            descriptor={
              isMaxAmountError ? (
                <Trans
                  i18nKey="deposit.form.amount-step.max-error"
                  components={{
                    click: (
                      <NumberInput.DescriptorButton
                        onClick={() => setValue(Fields.AMOUNT, formatInputNumberValue(limitMax, decimalScale))}
                      />
                    ),
                    value: <NumberFormat value={limitMax} decimalScale={decimalScale} currency={currency} />,
                  }}
                />
              ) : isMinAmountError ? (
                <Trans
                  i18nKey="deposit.form.amount-step.min-error"
                  components={{
                    click: (
                      <NumberInput.DescriptorButton
                        onClick={() => setValue(Fields.AMOUNT, formatInputNumberValue(limitMin, decimalScale))}
                      />
                    ),
                    value: <NumberFormat value={limitMin} decimalScale={decimalScale} currency={currency} />,
                  }}
                />
              ) : null
            }
            {...field}
            onChange={value => {
              setCurrentAmount(value);
              field.onChange(value);
            }}
          />
        </div>

        {bonus && (
          <div className="mt-4 rounded-lg bg-control-bg px-4 py-3">
            <div className="flex items-center justify-between gap-3">
              <div className="flex items-center gap-0.5">
                <Text variant="S / Regular" color="primary">
                  {!bonusAmount ? (
                    t("deposit.form.get-bonus")
                  ) : (
                    <Trans
                      i18nKey="deposit.form.get-bonus-2"
                      components={{
                        value: <NumberFormat value={bonusAmount} decimalScale={decimalScale} currency={currency} />,
                      }}
                    />
                  )}
                </Text>

                <BonusTooltip
                  bonus={bonus}
                  actionText={t("deposit.bonus.button")}
                  content={t("deposit.bonus.description", {
                    percent: getBonusMainPercent(bonus),
                  })}
                />
              </div>
              <Switch checked={isBonusAccepted} onCheckedChange={setIsBonusAccepted} />
            </div>
          </div>
        )}

        <PaymentFooter offset="sm">
          <SubmitButton fullWidth>{isMoreThanLimit ? t("button.go-to-verify") : t("button.next")}</SubmitButton>
        </PaymentFooter>
      </HookForm>

      <VerificationContainer
        isSurveyMandatory={isSurveyMandatory}
        verificationRoute={verificationRoute}
        withdrawLimit={withdrawLimit}
        decimalScale={decimalScale}
        currency={currency}
        open={isVerificationDialogVisible}
        depositLimit={currentLimitBeforeKyc}
        submitDeposit={next}
        onClose={closeVerificationDialog}
      />
    </>
  );
};

export { AmountStep };
