import * as ToggleGroup from "@radix-ui/react-toggle-group";
import { type ComponentPropsWithoutRef, type ElementRef, forwardRef, type ReactNode } from "react";

import { IconTick } from "@/domains/icons";
import type { PaymentMethodImages } from "@/services/openapi";
import { Image, Text } from "@/shared/ui";

type Props = Omit<
  ComponentPropsWithoutRef<typeof ToggleGroup.Root>,
  "defaultValue" | "value" | "onValueChange" | "type"
> & {
  defaultValue?: string;
  value?: string;
  onValueChange?: (value: string) => void;
};

const PaymentMethodToggleGroup = forwardRef<ElementRef<typeof ToggleGroup.Root>, Props>(
  ({ onValueChange, ...props }, ref) => (
    <ToggleGroup.Root
      className="overflow-hidden rounded-lg"
      orientation="vertical"
      type="single"
      onValueChange={
        onValueChange
          ? value => {
              if (value) onValueChange(value);
              else if (props.value) onValueChange(props.value);
            }
          : void 0
      }
      ref={ref}
      {...props}
    />
  ),
);

type ItemProps = Pick<ComponentPropsWithoutRef<typeof ToggleGroup.Item>, "disabled" | "value"> & {
  images: PaymentMethodImages;
  title: ReactNode;
  description?: ReactNode;
};

const Item = forwardRef<ElementRef<typeof ToggleGroup.Item>, ItemProps>(
  ({ disabled, images, title, description, ...props }, ref) => (
    <ToggleGroup.Item
      className="group flex w-full items-center gap-3 bg-card-bg p-4 pe-0 outline-none transition-colors hover:bg-card-bg-hover focus-visible:bg-card-bg-hover disabled:pointer-events-none data-[state=on]:bg-card-bg-active"
      disabled={disabled}
      {...props}
      ref={ref}
    >
      <Image src={images.mini!} className="size-6" />
      <div className="relative -my-4 flex grow items-center gap-3 py-4">
        <div className="flex grow flex-col gap-1.5 py-0.5">
          <Text as="span" variant="M Compact / Medium" color={disabled ? "secondary" : "primary"}>
            {title}
          </Text>
          {description && (
            <Text variant="S / Regular" color="secondary">
              {description}
            </Text>
          )}
        </div>

        <div className="hidden pe-4 group-data-[state=on]:block">
          <IconTick />
        </div>

        <div className="absolute bottom-0 h-px w-full bg-contrast-quaternary group-last:hidden group-hover:hidden group-focus-visible:hidden group-data-[state=on]:hidden" />
      </div>
    </ToggleGroup.Item>
  ),
);

const Component = Object.assign(PaymentMethodToggleGroup, {
  Item,
});

export { Component as PaymentMethodToggleGroup };
