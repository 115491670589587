import type { FC } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";

import { Logo } from "@/app/components";
import { LanguageSwitcherContainer as LanguageSwitcherDialogContainer } from "@/features/language-switcher";
import { LanguageSwitcherContainer } from "@/features/language-switcher/container";
import { useDisclosure } from "@/hooks/disclosure.hook";
import { useScreenSize } from "@/hooks/screen-size.hook";
import { authRoutes } from "@/routes/auth.routes";
import { Button, Image } from "@/shared/ui";

const AuthHeader: FC = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { isDesktop } = useScreenSize();
  const isSignInPage = pathname === authRoutes.signIn;

  const [languageSwitcherOpened, { open: openLanguageSwitcher, onOpenChange: onOpenChangeLanguageSwitcher }] =
    useDisclosure();

  return (
    <header className="flex h-20 shrink-0 items-center justify-between px-6 lg:px-16">
      <Logo />
      <div className="flex items-center gap-6">
        <LanguageSwitcherContainer>
          {({ icon, name }) => (
            <Button
              size="sm"
              variant="flat"
              startSection={<Image src={icon!} alt={name!} />}
              endSection={null}
              onClick={openLanguageSwitcher}
            >
              {name}
            </Button>
          )}
        </LanguageSwitcherContainer>
        <LanguageSwitcherDialogContainer onOpenChange={onOpenChangeLanguageSwitcher} open={languageSwitcherOpened} />

        {isDesktop && (
          <Button size="sm" asChild>
            <Link to={isSignInPage ? authRoutes.signUp : authRoutes.signIn}>
              {isSignInPage ? t("auth.sign-up.link") : t("auth.sign-in.link")}
            </Link>
          </Button>
        )}
      </div>
    </header>
  );
};

export { AuthHeader };
