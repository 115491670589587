import type { FC } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import negativeImage from "@/assets/images/negative.png";
import positiveImage from "@/assets/images/positive.png";
import { authRoutes } from "@/routes/auth.routes";
import { cabinetRoutes } from "@/routes/cabinet.routes";
import { Button, Image, Spinner } from "@/shared/ui";
import { useConfirmEmailMutation } from "@/state/server/auth";

import { AuthWrapper } from "../../wrapper";

type Props = {
  userId: string;
  code: string;
};

const EmailConfirmContainer: FC<Props> = ({ userId, code }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { mutate, error, isLoading } = useConfirmEmailMutation({ showErrorMessage: false });

  useEffect(() => {
    mutate({ code, userId });
  }, []);

  if (isLoading) {
    return (
      <div className="grid place-items-center">
        <div className="grid place-items-center rounded-xl bg-surface-elevation-1 p-14 *:size-12">
          <Spinner />
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <AuthWrapper title={error.errorMessage} image={<Image src={negativeImage} />}>
        <Button fullWidth className="mt-2" onClick={() => navigate(authRoutes.signIn, { replace: true })}>
          {t("auth.email-confirm.error-action")}
        </Button>
      </AuthWrapper>
    );
  }

  return (
    <AuthWrapper title={t("auth.email-confirm.success-title")} image={<Image src={positiveImage} />}>
      <Button fullWidth className="mt-2" onClick={() => navigate(cabinetRoutes.dashboard, { replace: true })}>
        {t("auth.email-confirm.success-action")}
      </Button>
    </AuthWrapper>
  );
};

export { EmailConfirmContainer };
