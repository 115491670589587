import Big from "big.js";
import { useCallback, useMemo, useState } from "react";

import { amplitudeEvents, track } from "@/app/libs/amplitude";
import { calculateBonus } from "@/features/payment/deposit/helpers/helpers";
import type { BonusUserPlatform, PaymentMethod, RateResult } from "@/services/openapi";

type DepositInfo = {
  currentStep: "paymentMethod" | "amount" | "check";
  // navigation
  backFromAmount: () => void;
  backFromCheck: () => void;
  nextFromPaymentMethod: () => void;
  nextFromAmount: () => void;
  // form
  isBonusAccepted: boolean;
  setIsBonusAccepted: (value: boolean) => void;
  currentAmount: string;
  setCurrentAmount: (value: string) => void;
  currentPaymentMethod: PaymentMethod | null;
  bonusAmount: number | void;
  setCurrentPaymentMethod: (method: PaymentMethod) => void;
};

const useDepositInfo = ({
  bonus,
  rate,
}: {
  bonus: BonusUserPlatform | null;
  rate: RateResult | undefined;
}): DepositInfo => {
  const [currentStep, setCurrentStep] = useState<DepositInfo["currentStep"]>("paymentMethod");
  const [currentPaymentMethod, setCurrentPaymentMethod] = useState<DepositInfo["currentPaymentMethod"]>(null);
  const [currentAmount, setCurrentAmount] = useState<DepositInfo["currentAmount"]>("");
  const [isBonusAccepted, setIsBonusAccepted] = useState<DepositInfo["isBonusAccepted"]>(true);

  // Bonus
  const bonusPercent = useMemo(() => {
    if (!currentAmount) return;

    if (!bonus || !rate) {
      return 0;
    }

    const value = new Big(currentAmount).div(rate.rate!).toNumber();
    return calculateBonus(value, bonus);
  }, [currentAmount, bonus, rate]);

  const bonusAmount = useMemo(() => {
    if (!bonusPercent) return;

    return new Big(bonusPercent).div(100).mul(currentAmount).toNumber();
  }, [bonusPercent, currentAmount]);

  // Payment method step
  const nextFromPaymentMethod = useCallback(() => {
    setCurrentStep("amount");
  }, []);

  // Amount step
  const nextFromAmount = useCallback(() => {
    setCurrentStep("check");
    track(amplitudeEvents.deposit.nextFromAmount, { deposit_amount: currentAmount });
  }, [currentAmount]);

  const backFromAmount = useCallback(() => {
    setCurrentStep("paymentMethod");
    setCurrentAmount("");
  }, []);

  // Check step
  const backFromCheck = useCallback(() => {
    setCurrentStep("amount");
    setIsBonusAccepted(true);
  }, []);

  return {
    currentStep,
    currentPaymentMethod,
    currentAmount,
    isBonusAccepted,
    bonusAmount,
    setIsBonusAccepted,
    setCurrentPaymentMethod,
    setCurrentAmount,
    backFromAmount,
    backFromCheck,
    nextFromAmount,
    nextFromPaymentMethod,
  };
};

export { useDepositInfo };
