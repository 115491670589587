import { type FC, useCallback, useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";

import { NumberFormat } from "@/app/components";
import { PaymentMethodToggleGroup } from "@/features/payment/ui/method-toggle-group";
import { useDisclosure } from "@/hooks/disclosure.hook";
import type { PaymentMethod, PaymentMethodCategoryCollection, TradingAccount } from "@/services/openapi";
import { Chip, Dialog } from "@/shared/ui";
import { useProfileData } from "@/state/server/profile/profile.hooks";

import { DialogContainer } from "./dialog-container";

type Props = {
  setPaymentMethod: (method: PaymentMethod) => void;
  paymentMethods: PaymentMethod[];
  next: () => void;
  currentPaymentMethod: PaymentMethod | null;
  currentAccount: TradingAccount;
  categories: PaymentMethodCategoryCollection[];
};

const PaymentMethodStep: FC<Props> = ({
  next,
  paymentMethods,
  setPaymentMethod,
  currentPaymentMethod,
  currentAccount,
  categories,
}) => {
  const [category, setCategory] = useState<string | null>(() => {
    const defaultCategory = categories.find(({ isDefault }) => isDefault);

    return defaultCategory?.title || categories[0]?.title || null;
  });

  const [opened, { open, close }] = useDisclosure();
  const { t } = useTranslation();
  const profileData = useProfileData();
  const onValueChange = useCallback(
    (value: string) => {
      const method = paymentMethods.find(({ title }) => title === value)!;
      setPaymentMethod(method);

      if (currentPaymentMethod?.mandatorySurvey) {
        open();
        return;
      }
      next();
    },
    [currentPaymentMethod?.mandatorySurvey, next, open, paymentMethods, setPaymentMethod],
  );

  const sortedPaymentMethods = useMemo(() => {
    if (!category) {
      return paymentMethods;
    }

    const paymentOrder = categories.find(({ title }) => title === category);

    if (!paymentOrder || !paymentOrder.paymentMethods?.length) {
      return paymentMethods;
    }

    const methods = paymentOrder.paymentMethods.map(({ recurringId, id }) =>
      paymentMethods.find(payment => `${payment.id}${payment.recurringId}` === `${id}${recurringId}`),
    );

    return methods as PaymentMethod[];
  }, [categories, category, paymentMethods]);

  return (
    <>
      {category && (
        <Chip.Group value={`${category}`} onValueChange={setCategory} className="mb-6 mt-3">
          {categories.map(({ title }) => (
            <Chip key={title} value={`${title}`}>
              {title}
            </Chip>
          ))}
        </Chip.Group>
      )}

      <PaymentMethodToggleGroup onValueChange={onValueChange}>
        {sortedPaymentMethods.map(({ title, images, details, isAvailable }) => {
          const from = details!.limit?.from;

          if (!isAvailable) {
            return null;
          }

          return <PaymentMethodToggleGroup.Item key={title} value={title!} title={title} images={images!} />;
        })}
      </PaymentMethodToggleGroup>

      <Dialog open={opened} onOpenChange={close}>
        <Dialog.Content>
          <DialogContainer isSurveyCompleted={profileData.options!.isSurveyCompleted!} />
        </Dialog.Content>
      </Dialog>
    </>
  );
};

export { PaymentMethodStep };
