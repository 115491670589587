import { type FC, type ReactElement, useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

import { useAuth } from "@/contexts/auth.context";
import { authRoutes } from "@/routes/auth.routes";
import { cabinetRoutes } from "@/routes/cabinet.routes";

const UnauthenticatedRoute: FC<{ children?: ReactElement }> = ({ children }) => {
  const { isAuthenticated } = useAuth();

  const location = useLocation();

  const navigate = useNavigate();

  useEffect(() => {
    if (
      isAuthenticated &&
      location.pathname !== authRoutes.emailConfirm &&
      location.pathname !== authRoutes.resetPassword
    ) {
      navigate(cabinetRoutes.dashboard, { replace: true });
    }
  }, []);

  return children ? children : <Outlet />;
};

export { UnauthenticatedRoute };
