import { type FC } from "react";

import { PaymentLoader } from "@/features/payment/ui/loader";
import type { BonusUserPlatform, PaymentMethod, TradingAccount } from "@/services/openapi";
import { useMaximumDepositAmountQuery, useMaximumWithdrawAmountQuery, usePaymentInfo } from "@/state/server/payment";
import { useProfileData } from "@/state/server/profile/profile.hooks";

import { AmountStep } from "./step";

type Props = {
  currentAccount: TradingAccount;
  currentPaymentMethod: PaymentMethod;
  currentAmount: string;
  bonus: BonusUserPlatform | null;
  isBonusAccepted: boolean;
  bonusAmount: number | void;
  setCurrentAmount: (value: string) => void;
  setIsBonusAccepted: (value: boolean) => void;
  next: () => void;
};

const AmountStepContainer: FC<Props> = ({
  currentAccount,
  currentPaymentMethod,
  next,
  setCurrentAmount,
  currentAmount,
  bonus,
  setIsBonusAccepted,
  isBonusAccepted,
  bonusAmount,
}) => {
  const profile = useProfileData();

  const { data: withdrawLimit } = useMaximumWithdrawAmountQuery({
    accountId: currentAccount.id!,
    paymentMethodId: currentPaymentMethod.id!,
    recurringId: currentPaymentMethod.recurringId!,
  });
  const { data: paymentsInfo } = usePaymentInfo();
  const { data: depositLimit } = useMaximumDepositAmountQuery({
    accountId: currentAccount.id,
    paymentMethodId: currentPaymentMethod.id!,
    recurringId: currentPaymentMethod.recurringId!,
  });

  return paymentsInfo && depositLimit && withdrawLimit ? (
    <AmountStep
      amountSuggestions={paymentsInfo.depositInfo!.uiAmountsInfo!.amounts![currentAccount.currency!]!}
      currentAccount={currentAccount}
      currentAmount={currentAmount}
      currentLimitBeforeKyc={depositLimit.extendedInfo!.depositCurrentLimitNoKyc!}
      currentPaymentMethod={currentPaymentMethod}
      next={next}
      isKycCompleted={profile.options!.isKycCompleted!}
      setCurrentAmount={setCurrentAmount}
      withdrawLimit={withdrawLimit}
      bonus={bonus}
      setIsBonusAccepted={setIsBonusAccepted}
      isBonusAccepted={isBonusAccepted}
      bonusAmount={bonusAmount}
    />
  ) : (
    <PaymentLoader />
  );
};

export { AmountStepContainer };
