import { type ComponentProps, type FC, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { ChangePasswordVerify2FAForm } from "@/features/profile/change-password/verify/two-factor/form";
import { useDisclosure } from "@/hooks/disclosure.hook";
import { cabinetRoutes } from "@/routes/cabinet.routes";
import { Dialog, toast, ToastIcons } from "@/shared/ui";
import { useResetPasswordMutation } from "@/state/server/auth";
import { usePlatformData } from "@/state/server/platform/platform.hooks";

import { AuthWrapper } from "../../wrapper";
import { ResetPasswordForm } from "./reset-password.form";
import { ResetPasswordSuccessDialog } from "./success-dialog";

type Props = {
  userId: string;
  code: string;
};

const ResetPasswordContainer: FC<Props> = ({ code, userId }) => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const [newPassword, setNewPassword] = useState("");

  const [openedSuccessDialog, { open: openSuccessDialog, close: closeSuccessDialog }] = useDisclosure();
  const [opened2FADialog, { open: open2FADialog, close: close2FADialog }] = useDisclosure();

  const platformInfo = usePlatformData();

  const { mutateAsync: resetPassword } = useResetPasswordMutation({
    showErrorMessage: false,
  });

  const { mutateAsync: resetPassword2FA } = useResetPasswordMutation();

  const handleSubmit: ComponentProps<typeof ResetPasswordForm>["onSubmit"] = useCallback(
    ({ password }) => {
      return resetPassword(
        { passwordResetRequest: { password, code, userId } },
        {
          onSuccess: () => {
            openSuccessDialog();
          },
          onError: error => {
            if (error.codeMessage === "Auth2FaRequired") {
              setNewPassword(password);
              open2FADialog();
              return;
            }
            error.errorMessage && toast({ title: error.errorMessage, icon: ToastIcons.ERROR });
          },
        },
      );
    },
    [resetPassword, userId, code, open2FADialog, openSuccessDialog],
  );

  const handle2FASubmit: ComponentProps<typeof ChangePasswordVerify2FAForm>["onSubmit"] = useCallback(
    ({ code: twoFactorCode }) => {
      return resetPassword2FA(
        { passwordResetRequest: { code, twoFactorCode, userId, password: newPassword } },
        {
          onSuccess: () => {
            close2FADialog();
            openSuccessDialog();
          },
        },
      );
    },
    [resetPassword2FA, userId, code, newPassword, openSuccessDialog, close2FADialog],
  );

  return (
    <>
      <AuthWrapper title={t("auth.reset-password.title")}>
        <ResetPasswordForm onSubmit={handleSubmit} passwordRules={platformInfo.password!} />
      </AuthWrapper>
      <Dialog
        open={openedSuccessDialog}
        onOpenChange={() => {
          closeSuccessDialog();
          navigate(cabinetRoutes.dashboard);
        }}
      >
        <Dialog.Content>
          <ResetPasswordSuccessDialog />
        </Dialog.Content>
      </Dialog>
      <Dialog open={opened2FADialog} onOpenChange={close2FADialog}>
        <Dialog.Content>
          <ChangePasswordVerify2FAForm onSubmit={handle2FASubmit} />
        </Dialog.Content>
      </Dialog>
    </>
  );
};

export { ResetPasswordContainer };
